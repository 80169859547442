import Vue from 'vue';
import con from '@/plugins/connection';
import cryptoSHA1 from 'crypto-js/sha1';
import cryptoSHA256 from 'crypto-js/sha256';
import cryptoEncHex from 'crypto-js/enc-hex';
import imageCompression from 'browser-image-compression';
import { send } from '../assets/js/s3';

// TODO:バックアップシステム動作時のメソッド実装
/**
 * @mixin
 */
const plugin = {
  install: function (Vue) {
    Vue.prototype.attachmentUploader = this;
    Vue.attachmentUploader = this;
  },
  /**
   * アップロードを開始する
   * @param {*} attachments
   * @param {*} id
   * @param {*} objectName
   */
  async start(attachments = [], recordId, objectName) {
    await Promise.all(
      attachments.map(async (attachment) => {
        const { file, progresEvent } = attachment;
        const compFile = await this.compressImange(file);

        // 保存ファイル名ハッシュ生成
        const fileName = await readFile(compFile, 5 * 1024 * 1024);

        // S3アップロード
        const { currentTarget = null } = await send(
          fileName,
          compFile,
          progresEvent,
        );
        console.log('%c uploadResponse ->', 'color: green;', currentTarget);
        attachment.uploadComplete = true;
        // ファイル情報をDBに保存
        await con.invoke({
          controller: 'CDS_CTR_Common',
          method: 'publishFile',
          params: {
            ObjectId: recordId,
            ObjectName: objectName,
            FileId: fileName,
            Name: file.name,
            Type: file.type,
            Size: compFile.size,
            LastModifiedDate: new Date()
          },
        });
        // if (Math.round(currentTarget.status / 10) !== 20) {
        //   console.error(currentTarget);
        //   throw new Error(
        //     'ファイルアップロードに失敗しました。ファイル名:' + file.name,
        //   );
        // }
      }),
    );
  },
  async compressImange(file) {
    // 画像ファイル以外は処理しない
    if (!/image\/.*/.test(file.type)) return file;
    const options = {
      maxSizeMB: 1, //最大画像サイズ
      maxWidthOrHeight: 1920,
    };
    return await imageCompression(file, options);
  },
};

async function readFile(file, readChunkSize) {
  const resultChunk = await new Promise((resolve, reject) => {
    // チャンクのoffset
    let offset = 0;
    // チャンク(arraybuffer[])
    let chunk = [];

    const fileReader = new FileReader();
    fileReader.onload = async function (e) {
      // チャンクに追加
      chunk.push(e.target.result);
      // 継続判定
      if (offset < file.size) {
        read();
      } else {
        resolve(chunk);
      }
    };
    fileReader.onerror = function (e) {
      console.error(e);
      reject(e);
    };

    read();

    function read() {
      // チャンク切り出し
      const chunk = file.slice(offset, offset + readChunkSize, file.type);
      offset += readChunkSize;
      // 読み込み
      fileReader.readAsArrayBuffer(chunk);
    }
  });

  // チャンクそれぞれをhashして文字列にする
  const stringChunks = await Promise.all(
    resultChunk.map(async (c) => {
      return convertHash(await arrayBufferToString(c), '');
    }),
  );

  // hashした文字列を連結してさらにhash化
  return convertHash(stringChunks.join('-'), 'blob', cryptoSHA256);
}

// arraybufferを文字列にする
async function arrayBufferToString(arrayBuffer) {
  return await new Blob([arrayBuffer]).text();
}

// ハッシュ化
function convertHash(text, prefix, cryptoMethod = cryptoSHA1) {
  const header = `${[`${prefix}`, `${text.length}`]
    .filter((v) => !!v)
    .join(' ')}\0`;
  const store = header + text;
  const hashed = cryptoMethod(store).toString(cryptoEncHex);
  return hashed;
}

Vue.use(plugin);
export default plugin;
