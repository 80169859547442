<template>
  <default-layout>
    <!-- ヘッダー -->
    <template #pageHeader>
      <page-header />
    </template>
    <!-- リストビュー -->
    <ListView
      v-bind="{
        objectName: 'CDS_T_DamageReport__c',
        detailPageName: 'CDS_VF_DamageReportDetail',
        showImageTab: true,
        showMapTab: true,
        isDisasterRelated: true,
        hasVersion: true,
      }"
      :table-props="{
        defaultListOptions,
        viewAttachment: true,
        viewComment: !le,
        inputFieldSetName: 'ListInputFieldSet,ListFieldSet',
        listFieldSetName: 'FixListFieldSet,ListFieldSet',
        defaultInputFieldSetName: 'ListInputFieldSet,DefaultListFieldSet',
        defaultListFieldSetName: 'FixListFieldSet,DefaultListFieldSet',
        bulkFieldSetName: 'BulkFieldSet',
        csvFieldSetName: 'ListInputFieldSet,FixListFieldSet,ListFieldSet',
        listSaveValidate: validate,
      }"
      :search-props="{
        syncConditionWithUrl: true,
        applyErrataToCondition: true,
        defaultSearchCondition,
      }"
      :google-map-props="{
        gisDynamicSettingName: 'GIS_C_D_DamageReport',
      }"
    >
      <template #listimage.default="{object}">
        <v-list>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-information</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>事案名称</v-list-item-title>
              <v-list-item-subtitle>
                {{ object.Name }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-card-account-details</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>事案概要</v-list-item-title>
              <v-list-item-subtitle
                class="overflow-auto"
                style="white-space: pre-wrap; max-height: 300px;"
              >
                {{ object.Detail__c }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-clock</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>事案発生日時</v-list-item-title>
              <v-list-item-subtitle style="white-space: pre-wrap;">
                {{ object.HappenDate__c | datetimesec }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
      </template>
    </ListView>
  </default-layout>
</template>

<script>
import defaultLayout from '@/components/layout/default';
import PageHeader from '@/components/app/PageHeader';
import ListView from '@/components/list/ListView';
import ActivityStatusLabel from '@/components/app/ActivityStatusLabel';
import { validateDamageReport } from '@/validation';

export default {
  components: {
    defaultLayout,
    PageHeader,
    ListView,
  },
  provide: {
    listItem: {
      default: [
        {
          slotName: 'item.ActivityStatus__c',
          component: ActivityStatusLabel,
        },
      ],
    },
  },
  data: () => ({
    defaultListOptions: {
      sortBy: ['LastModifiedDate'],
      sortDesc: [true],
    },
  }),

  computed: {
    le() {
      return this.$query.current().le === 'true';
    },
  },

  methods: {
    // デフォルト検索条件
    defaultSearchCondition() {
      let result = {};

      if (this.le) {
        result.CDS_M_Organization__c = [this.$store.state.user.organization.Id];
      }

      return result;
    },

    validate(listData) {
      if (!listData || listData.length === 0) {
        return true;
      }
      // 共通のバリデート
      const result = new Set(
        _.flatten(
          listData
            .map(validateDamageReport)
            .filter((v) => !!v)
            .map((v) => v.result),
        ),
      );
      if (result.size > 0) return [...result].join('\n');

      return true;
    },
  },
};
</script>
