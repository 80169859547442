<template>
  <detail
    use-version
    object-name="CDS_T_DamageReport__c"
    layout-name="DamageReportLayout"
    default-title="新規事案報告作成"
    subtitle="事案報告詳細"
    saved-page="CDS_VF_DamageReportDetail"
    detail-page-name="CDS_VF_DamageReportDetail"
    search-layout-name="CDS_T_DamageReport__c-検索レイアウト"
    list-page="CDS_VF_DamageReportList?le=true"
    :every-overwrite-value="everyOverwriteValue"
    :init-value="initValue"
    :override="override"
    use-correction
    :custom-validations="customValidations"
  >
    <template #DetailMenus="{editMode, value, objectName, savedPage, listPage}">
      <template
        v-if="
          !editMode &&
          value.CDS_M_Organization__c === $store.state.user.organization.Id
        "
      >
        <Transfer
          :object-name="objectName"
          :value="value"
          :next-page="listPage || savedPage"
          :org-field-names="['CDS_M_Organization__c', 'CreatedOrganization__c']"
        />
      </template>
    </template>
    <template #underHeader="{editMode,isFixed,value}">
      <v-stepper
        v-if="value.Id == null || value.Version__c == 0"
        :alt-labels="$vuetify.breakpoint.mobile"
        class="elevation-0 mb-n3"
        :value="editMode ? 1 : isFixed ? 3 : 2"
      >
        <v-stepper-header>
          <v-stepper-step step="1">
            入力
          </v-stepper-step>
          <v-divider />
          <v-stepper-step step="2">
            確定
          </v-stepper-step>
          <v-divider />
          <v-stepper-step step="3">
            完了
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
    </template>
  </detail>
</template>

<script>
import detail from '@/components/detail/';
import ActivityStatusLabel from '@/components/app/ActivityStatusLabel';
import { validateDamageReport } from '@/validation';

export default {
  components: {
    detail: {
      ...detail,
      methods: {
        ...detail.methods,
        /**
         * データ読み出しをオーバラライド
         */
        _loadData: detail.methods.loadData,
        loadData: async function () {
          // 通常読み出しを実行
          await this._loadData();
          // データによって非表示セクションを設定
          if (!this.value.Id) {
            this.hiddenSectionNames.push('対応状況');
          }
        },
        /**
         * レイアウト読み出しをオーバラライド
         */
        _loadLayout: detail.methods.loadLayout,
        loadLayout: async function () {
          await this._loadLayout();
          // 緯度経度登録項目の入力タイプを図形入力に変更
          this.objectInfo.properties.Location__c.type = 'features';
          // 画面の中心点を住所に設定ボタンクリック時の動作を指定
          this.objectInfo.properties.Location__c.reverseGeocodingEventhandler = ({
            address_components,
            formatted_address,
            utmPromise,
          }) => {
            // 発生場所に設定
            this.$set(this.value, 'Address__c', formatted_address);
            // 事案発生区名に設定
            const ward = address_components.find(({ types }) =>
              types.includes('sublocality_level_1'),
            );
            if (ward && ward.long_name) {
              this.$set(this.value, 'Ward__c', ward.long_name);
            }
            // UTM座標に設定
            utmPromise.then((v) => {
              this.$set(this.value, 'UtmPoint__c', v);
            });
          };
        },
      },
    },
    // Transfer,
  },

  provide: {
    listItem: {
      // 履歴の一覧のスロットの指定
      detail_history: [
        {
          slotName: 'item.ActivityStatus__c',
          component: ActivityStatusLabel,
        },
      ],
    },
  },

  data: (vm) => ({
    override: {
      // 入力チェック
      async validate({ value }) {
        // チェック実施
        const result = validateDamageReport(value);
        // nullであれば問題なし、文字列ならば問題あり
        if (result) throw new Error(result.message);
      },
    },
    customValidations: [
      {
        fieldName: 'SurvivorNum__c',
        value: {
          validation: ['v1', 'v2'],
          validationRules: {
            v1: ({ getFormValues }) => {
              const values = getFormValues();
              const { SurvivorCondition__c, SurvivorNum__c } = values;
              if (SurvivorCondition__c === '確定（入力時点の人数として）') {
                return !!SurvivorNum__c || SurvivorNum__c === 0;
              }
              return true;
            },
            v2: ({ getFormValues }) => {
              const values = getFormValues();
              const { SurvivorCondition__c, SurvivorNum__c } = values;
              if (SurvivorCondition__c !== '確定（入力時点の人数として）') {
                return (
                  !SurvivorNum__c &&
                  SurvivorNum__c !== 0 &&
                  SurvivorNum__c !== '0'
                );
              }
              return true;
            },
          },
          validationMessages: {
            v1:
              '「要救助者数の確認状況」が"確定（入力時点の人数として）"の時は「現時点の要救助者数」は必須項目です。',
            v2:
              '「要救助者数の確認状況」が"確定（入力時点の人数として）"以外の時は「現時点の要救助者数」は入力しないでください。',
          },
        },
      },
      {
        fieldName: 'SendFujisan__c',
        value: {
          validation: ['v1'],
          validationRules: {
            v1: ({ getFormValues }) => {
              const values = getFormValues();
              const { SendFujisan__c } = values;
              // fujisan連携していれば問題なし
              if (vm.isFujisanConnected) return true;
              // fujisan連携していなくてtrueの場合はダメ
              if (SendFujisan__c) return false;
              return true;
            },
          },
          validationMessages: {
            v1: 'FUJISAN連携されていないためチェックを付けることはできません。',
          },
        },
      },
    ],
  }),

  computed: {
    // 初期値の設定
    initValue() {
      const {
        user: { organization },
      } = this.$store.state;
      return {
        ...this.$util.getDefaultInitValue(this.$store.state),
        // 作成組織
        CreatedOrganization__c: organization.Id,
        // SendFujisan__c: this.isFujisanConnected ? true : false,
      };
    },
    // 編集時に上書きする値
    everyOverwriteValue() {
      return this.$util.getDefaultEveryOverwriteValue(this.$store.state);
    },
    // fujisan連携しているか
    isFujisanConnected() {
      return this.$store.getters['disaster/isFujisanConnected'];
    },
  },

  methods: {
    // 帳票出力
    onClickDocument(id) {
      this.$transition.to(
        'CDS_VF_DamageReportDocument',
        {
          id,
        },
        {
          notApplyRetUrl: true,
          openNewTab: true,
        },
      );
    },
  },
};
</script>
<style></style>
