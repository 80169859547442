<template>
  <v-row>
    <v-col cols="12">
      <v-card elevation="0">
        <div v-if="Object.keys(staffObjectInfo).length > 0">
          <Layout
            v-model="search.value"
            v-bind="search.attrs"
            :object-info="staffObjectInfo"
            @submit-raw="handleOnSubmit"
          />
        </div>
        <v-card-actions>
          <v-btn depressed color="primary" @click="handleClickSearchButton">
            検索
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <template v-if="reportTimeOptions.length > 0">
      <v-col cols="12">
        <v-card tile>
          <v-card-title>
            参集人員
          </v-card-title>
          <div class="mx-5" style="display: flex; flex-direction: row;">
            <div style="display: flex; flex-direction: column;">
              <v-btn icon style="padding: 0;" x-small @click="increase">
                <v-icon>
                  mdi-chevron-up
                </v-icon>
              </v-btn>
              <v-btn icon style="padding: 0;" x-small @click="decrease">
                <v-icon>
                  mdi-chevron-down
                </v-icon>
              </v-btn>
            </div>
            <v-select
              v-model="selectedReportTimeId"
              outlined
              dense
              hide-details="auto"
              persistent-hint
              :items="reportTimeOptions"
              label="報告日時"
              :menu-props="{ maxHeight: '500px' }"
            >
            </v-select>
          </div>
          <div class="ma-5">
            <FormulateForm
              v-model="staffNumForm.value"
              v-bind="staffNumForm.attrs"
              @submit-raw="handleOnSave"
            >
              <FormulateInput
                type="group"
                name="staffNum"
                repeatable
                remove-position="after"
                class="staffnum-area"
              >
                <v-row class="py-3">
                  <field
                    v-for="f in staffNumFields"
                    :key="f.fieldPath"
                    v-bind="f"
                    :edit-mode="editMode"
                  >
                  </field>
                </v-row>
                <template #remove>
                  <span />
                </template>
                <template #addmore>
                  <span />
                </template>
                <template #label>
                  <span />
                </template>
              </FormulateInput>
            </FormulateForm>
          </div>
        </v-card>
      </v-col>
      <v-footer fixed outlined style="bottom: 0px;" elevation="5">
        <div class="flex text-center">
          <template v-if="editMode">
            <v-btn depressed class="mx-2" @click="handleClickCancel">
              キャンセル
            </v-btn>
            <Confirm
              message="保存します。よろしいですか？"
              @on-click-yes="handleClickSaveButton"
            >
              <template #default="{ on }">
                <v-btn depressed class="mx-2" color="primary" v-on="on">
                  <v-icon left>
                    mdi-content-save
                  </v-icon>
                  保存
                </v-btn>
              </template>
            </Confirm>
          </template>
          <template v-else>
            <v-btn depressed color="primary" outlined @click="editMode = true">
              <v-icon left>
                mdi-file-edit
              </v-icon>
              編集
            </v-btn>
          </template>
        </div>
      </v-footer>
    </template>
    <template v-else>
      <v-col cols="12">
        <p>報告用の報告日時がありません。</p>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import field from '@/components/detail/field/index.vue';
import Layout from '@/components/detail/Layout';
import Confirm from '@/components/common/Confirm';

import { reportSearchLayout, staffNumFields } from './form';
import { mapActions } from 'vuex';

export default {
  name: 'StaffListInnerContent',

  components: {
    field,
    Layout,
    Confirm,
  },

  data: () => ({
    staffNumFields,

    editMode: true,

    objectInfo: {},
    staffObjectInfo: {},

    // 検索条件
    search: {
      attrs: {
        name: 'ReportSearchForm',
        layout: reportSearchLayout,
        editMode: true,
      },
      value: {
        // 選択済みの組織
        CDS_M_Organization__c: [],
      },
    },

    // 報告日時レコード
    reportTimeRecords: [],
    // 選択済みの報告日時レコード
    selectedReportTimeId: null,
    currentIndex: null,

    staffNumForm: {
      attrs: {
        name: 'StaffNumForm',
      },
      value: {
        staffNum: [],
      },
    },
  }),

  computed: {
    // 報告日時の選択肢
    reportTimeOptions() {
      return this.reportTimeRecords.map((r) => ({
        value: r.Id,
        text: r.IsSetDate__c
          ? this.$options.filters?.datetime(r.ReportDate__c)
          : '体制設置日時',
      }));
    },
    // 報告日時の表示
    displayReportTime() {
      return this.reportTimeOptions.find(
        (v) => v.value === this.selectedReportTimeId,
      )?.text;
    },
  },

  watch: {
    // 選択済みの報告日時レコード
    selectedReportTimeId: {
      handler() {
        this.loadStaffNum();
        this.currentIndex = this.reportTimeOptions.findIndex(option => option.value === this.selectedReportTimeId);
      },
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      // 組織Idを設定
      const {
        user: { organization },
      } = this.$store.state;
      this.search.value.CDS_M_Organization__c = [organization.Id];
      // ロード
      await this.$store.dispatch(
        'loading/register',
        (async () => {
          await Promise.all([this.loadObjectInfo(), this.loadReportTime()]);
          await Promise.all([this.loadStaffNumRecords()]);
        })(),
      );
    },

    // オブジェクト情報の読み込み
    async loadObjectInfo() {
      const [objectInfo, staffObjectInfo] = await Promise.all(
        ['StaffAndStaffReportTime', 'CDS_T_Staff__c'].map(
          async (objectName) => {
            return await this.$util.getObjectInfo(objectName);
          },
        ),
      );
      if (objectInfo) {
        this.$set(this, 'objectInfo', objectInfo);
      }
      if (staffObjectInfo) {
        _.set(
          staffObjectInfo,
          ['properties', 'CDS_M_Organization__c', 'multiple'],
          true,
        );
        this.$set(this, 'staffObjectInfo', staffObjectInfo);
      }
    },

    // 報告日時の読み込み
    async loadReportTime() {
      const result =
        (await this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: 'getReportTime',
          params: {
            disasterId: this.$store.state.disaster.disaster.Id,
          },
        })) || {};
      const { records } = result;
      this.reportTimeRecords = records;
      // 初期値の設定
      if (records.length > 0) {
        // とりあえず1番目を設定
        this.selectedReportTimeId = records[0].Id;
        // 現在時刻より前のもので最新のものを選択
        const current = new Date();
        const latest = records
          .filter((r) => !!r.ReportDate__c)
          .map((r) => ({
            ...r,
            ReportDate__c: new Date(r.ReportDate__c),
          }))
          .filter((r) => r.ReportDate__c <= current)
          .reduce((prev, next) => {
            if (!prev?.ReportDate__c) return next;
            return prev.ReportDate__c < next.ReportDate__c ? next : prev;
          }, null);
        if (latest) {
          this.selectedReportTimeId = latest.Id;
        }
      }
    },

    async loadStaffNum() {
      await this.$store.dispatch(
        'loading/register',
        this.loadStaffNumRecords(),
      );
    },

    // 参集人員の読み込み
    async loadStaffNumRecords() {
      if (!this.selectedReportTimeId) return;
      const result =
        (await this.$con.invoke({
          controller: this.$pageProperty.controller,
          method: 'getStaffNumRecords',
          params: {
            disasterId: this.$store.state.disaster.disaster.Id,
            organizationIds: [...this.search.value.CDS_M_Organization__c],
            reportTimeId: this.selectedReportTimeId,
            prevReportTimeId: this.reportTimeOptions[
              this.reportTimeOptions.indexOf(
                this.reportTimeOptions.find(
                  (v) => v.value === this.selectedReportTimeId,
                ),
              ) - 1
            ]?.value,
          },
        })) || {};
      const { records } = result;
      this.$formulate.setValues(this.staffNumForm.attrs.name, {
        staffNum: records.map((r) => ({
          ...r,
          ReportDate__c: this.displayReportTime,
        })),
      });
    },

    // 人数の保存
    async saveStaffNum() {
      await this.$nextTick();
      const {
        user: { user },
      } = this.$store.state;
      try {
        await this.$store.dispatch(
          'loading/register',
          this.$con.invoke({
            controller: this.$pageProperty.controller,
            method: 'saveStaffNumRecords',
            params: {
              reportTimeId: this.selectedReportTimeId,
              records: this.staffNumForm.value.staffNum.map((s) => {
                return {
                  ...s,
                  // 参集人員報告で暗黙的に入力者氏名を設定する場合は必須項目である氏名を設定する
                  Reporter__c: user.Name,
                  ReporterAffiliation__c: user.Department__c,
                  ReporterTel__c: user.Phone,
                };
              }),
            },
          }),
        );
        this.saveComplete('保存しました。');
        this.editMode = false;
        // 再読み込み
        this.loadStaffNum();
      } catch (error) {
        this.saveFail(error.message);
      }
    },

    /******** ハンドラ ********/

    handleClickSearchButton() {
      this.$formulate.submit(this.search.attrs.name);
    },

    // formでsubmitがコールされた時
    async handleOnSubmit(form) {
      try {
        // フォーム入力値に不正がないかチェック
        if (await form.hasValidationErrors()) {
          throw new Error('入力内容にエラーがあります.');
        }

        await this.loadStaffNumRecords();
      } catch (error) {
        this.saveFail(error.message);
      }
    },

    handleClickSaveButton() {
      this.$formulate.submit(this.staffNumForm.attrs.name);
    },

    async handleOnSave(form) {
      try {
        // フォーム入力値に不正がないかチェック
        if (await form.hasValidationErrors()) {
          throw new Error('入力内容にエラーがあります.');
        }

        await this.saveStaffNum();
      } catch (error) {
        this.saveFail(error.message);
      }
    },

    handleClickCancel() {
      this.editMode = false;
      this.loadStaffNum();
    },

    /******** 共通 ********/

    ...mapActions('snackbar', [
      'saveComplete',
      'saveFail',
      'openSnackBar',
      'openErrorSnackBar',
    ]),

    // 報告時間を選択
    increase() {
      const nextIndex = this.currentIndex + 1;
      if (nextIndex < this.reportTimeOptions.length) {
        this.selectedReportTimeId = this.reportTimeOptions[nextIndex].value;
      }
    },
    decrease() {
      const prevIndex = this.currentIndex - 1;
      if (prevIndex >= 0) {
        this.selectedReportTimeId = this.reportTimeOptions[prevIndex].value;
      }
    },
  },
};
</script>

<style lang="scss">
.staffnum-area {
  .formulate-input-grouping {
    .formulate-input-group-repeatable {
      display: flex;

      &:not(:last-child) {
        border-bottom: 1px solid gray;
      }
    }
  }
}
</style>
